<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card card-custom gutter-b example example-compact">
        <div
          class="card-header d-flex align-items-center justify-content-between"
        >
          <div class="card-title">
            <h3 class="card-label">{{ $t('MENU.SALE.ACT_SVERKA') }}</h3>
          </div>
          <div class="card-button">
            <v-row justify="center">
              <v-dialog v-model="dialog" persistent max-width="600px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="success" small v-bind="attrs" v-on="on">
                    {{ $t('BREADCRUMBS.ACT_SVERKA') }} +
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">
                      {{ $t('BREADCRUMBS.ACT_SVERKA') }} +</span
                    >
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <div>
                        <v-autocomplete
                          v-model="combobox"
                          v-debounce:400ms="clientFun"
                          :items="allClientNames"
                          @change="allClientContract(combobox)"
                          item-text="full_name"
                          item-value="id"
                          hide-no-data
                          :label="this.$t('INPUTS.CLIENT_NAME')"
                          :error-messages="clientError"
                          required
                          dense
                          @input="$v.combobox.$touch()"
                          @blur="$v.combobox.$touch()"
                          outlined
                        ></v-autocomplete>
                      </div>
                      <div v-show="client_contract">
                        <div v-if="getContractByClient.length == '0'">
                          <v-text-field
                            label="Shartnoma topilmadi"
                            outlined
                            dense
                            required
                            disabled
                          ></v-text-field>
                        </div>
                        <div v-else>
                          <!-- {{ ContractByClientInput.id }} -->
                          <v-select
                            v-model="ContractByClientInput"
                            :items="getContractByClient"
                            item-text="contract_number"
                            item-value="id"
                            :label="$t('TABLE_HEADER.CONTRACT')"
                            return-object
                            dense
                            outlined
                            :error-messages="clientContract"
                            required
                            @input="$v.ContractByClientInput.$touch()"
                            @blur="$v.ContractByClientInput.$touch()"
                          ></v-select>
                        </div>
                      </div>
                      <div>
                        <v-dialog
                          ref="start_dialog"
                          v-model="start_modal"
                          :return-value.sync="newstart_date2"
                          persistent
                          width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              :label="$t('INPUTS.REPORT_START')"
                              append-icon="event"
                              readonly
                              :value="newstart_date2 | dateFilter"
                              dense
                              outlined
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker v-model="newstart_date2" scrollable>
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="primary"
                              @click="start_modal = false"
                              >Bekor Qilish</v-btn
                            >
                            <v-btn
                              text
                              color="primary"
                              @click="$refs.start_dialog.save(newstart_date2)"
                              >OK</v-btn
                            >
                          </v-date-picker>
                        </v-dialog>
                      </div>

                      <div>
                        <!-- {{ end_date }} -->
                        <v-dialog
                          ref="end_dialog"
                          v-model="end_modal"
                          :return-value.sync="end_date"
                          persistent
                          width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              append-icon="event"
                              readonly
                              :label="$t('INPUTS.REPORT_END')"
                              dense
                              outlined
                              :value="end_date | dateFilter"
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker v-model="end_date" scrollable>
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="primary"
                              @click="end_modal = false"
                              >Bekor Qilish</v-btn
                            >
                            <v-btn
                              text
                              color="primary"
                              @click="$refs.end_dialog.save(end_date)"
                              >OK</v-btn
                            >
                          </v-date-picker>
                        </v-dialog>
                      </div>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      :disabled="aktSverkaLoading"
                      color="error"
                      @click="dialog = false"
                    >
                      {{ $t('FORM.CANCEL') }}</v-btn
                    >
                    <!-- @click="dialog = false"   -->
                    <v-btn
                      color="success"
                      :disabled="aktSverkaLoading"
                      @click="submit()"
                    >
                      <i class="el-icon-loading" v-if="aktSverkaLoading"></i>

                      {{ $t('FORM.SUBMIT') }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-row>
          </div>
        </div>

        <div class="card-body">
          <v-data-table
            :headers="headers"
            :items="getActRecon"
            :loading="isLoading"
            loading-text="Malumot yuklanmoqda..."
            hide-default-footer
            @click:row="rowClick"
            item-key="id"
            :single-select="singleSelect"
            class="elevation-1 row-pointer fixed-header"
          >
            <template v-slot:[`item.index`]="{ item }">
              {{
                getActRecon
                  .map(function (x) {
                    return x.id
                  })
                  .indexOf(item.id) + 1
              }}
            </template>
            <!-- date1 -->
            <template v-slot:[`item.report_date`]="{ item }">
              {{ item.report_date | formatDate }}
            </template>
            <!-- date2 -->
            <template v-slot:[`item.report_start_date`]="{ item }">
              {{ item.report_start_date | formatDate }}
            </template>
            <!-- date3 -->
            <template v-slot:[`item.report_end_date`]="{ item }">
              {{ item.report_end_date | formatDate }}
            </template>
            <!-- date4 -->
            <template v-slot:[`item.created_at`]="{ item }">
              {{ item.created_at | formatDate }}
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <allActReconAction :index="item"></allActReconAction> </template
          ></v-data-table>
          <div class="text-left my-5">
            <v-pagination
              v-model="currentPage"
              :total-visible="10"
              @input="getPostData(currentPage)"
              :length="Math.ceil(getCount / 10)"
            ></v-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import allActReconAction from '@/view/pages/clients/invoice/allActReconAction.vue'

export default {
  mixins: [validationMixin],
  validations: {
    combobox: { required },
    ContractByClientInput: { required }
  },
  data() {
    return {
      aktSverkaLoading: false,
      counter: 0, // count the clicks
      timer: null, // Needs to be specified here so it can be accessed on both clicks
      selectedId: -1,
      end_date: new Date().toISOString().substr(0, 10),
      newstart_date2: '',
      // report_end_date: new Date().toISOString().substr(0, 10),
      menu: false,
      modal: false,
      date: '',
      menu2: false,
      start_modal: false,
      end_modal: false,
      client_contract: false,
      combobox: '',
      dialog: false,
      end_dialog: false,
      perPage: 10,
      currentPage: 1,
      ContractByClientInput: '',
      singleSelect: true,
      selected: []
    }
  },
  created() {
    if (this.$route.query.page !== undefined) {
      this.getPostData(parseInt(this.$route.query.page, 10))
      this.currentPage = parseInt(this.$route.query.page, 10)
    } else {
      this.getPostData(this.currentPage)
    }
  },
  computed: {
    headers() {
      return [
        {
          text: '#',
          value: 'index'
        },
        {
          text: this.$t('TABLE_HEADER.CLIENT'),
          value: 'client_name'
        },
        {
          text: this.$t('AKT_SVERKA.REPORT_DATE') + 'Hisobot sanasi',
          value: 'report_date'
        },
        {
          text: this.$t('AKT_SVERKA.REPORT_DATE_START') + 'Hisobot boshi',
          value: 'report_start_date'
        },
        {
          text: this.$t('AKT_SVERKA.REPORT_DATE_END') + 'Hisobot oxiri',
          value: 'report_end_date'
        },
        {
          text:
            this.$t('AKT_SVERKA.BTS_DEBET_SALDO_START') +
            'BTS debet saldo boshida',
          value: 'debet_start_saldo_company'
        },
        {
          text:
            this.$t('AKT_SVERKA.BTS_KREDIT_SALDO_START') +
            'BTS kredit saldo boshida',
          value: 'credit_start_saldo_company'
        },
        {
          text:
            this.$t('AKT_SVERKA.CLIENT_DEBET_SALDO_START') +
            'Mijoz debet saldo boshida',
          value: 'debet_start_saldo_client'
        },
        {
          text:
            this.$t('AKT_SVERKA.CLIENT_KREDIT_SALDO_START') +
            'Mijoz kredit saldo boshida',
          value: 'credit_start_saldo_client'
        },
        {
          text:
            this.$t('AKT_SVERKA.BTS_DEBET_SALDO_END') +
            'BTS debet saldo oxirida',
          value: 'debet_end_saldo_company'
        },
        {
          text:
            this.$t('AKT_SVERKA.BTS_KREDIT_SALDO_END') +
            'BTS kredit saldo oxiri',
          value: 'credit_end_saldo_company'
        },
        {
          text:
            this.$t('AKT_SVERKA.CLIENT_DEBET_SALDO_END') +
            'Mijoz debet saldo oxiri',
          value: 'debet_end_saldo_client'
        },
        {
          text:
            this.$t('AKT_SVERKA.CLIENT_KREDIT_SALDO_END') +
            'Mijoz kredit saldo oxiri',
          value: 'credit_end_saldo_client'
        },
        {
          text: this.$t('AKT_SVERKA.CONTRACT_NUMBER') + 'Kontrakt raqam',
          value: 'contract_number'
        },
        {
          text: this.$t('AKT_SVERKA.CREATED') + 'Yaratilgan sana',
          value: 'created_at'
        },
        {
          text: '',
          value: 'action',
          sortable: false
        }
      ]
    },
    isLoading() {
      return this.$store.state.requests.isLoading
    },
    clientError() {
      const errors = []
      if (!this.$v.combobox.$dirty) return errors
      !this.$v.combobox.required &&
        errors.push('Mijoz nomi toldirilishi shart.')
      return errors
    },
    clientContract() {
      const errors = []
      if (!this.$v.ContractByClientInput.$dirty) return errors
      !this.$v.ContractByClientInput.required &&
        errors.push('Mijoz shartnomasi toldirilishi shart.')
      return errors
    },
    getActRecon() {
      const data = this.$store.state.requests.allActRecon.results
      return data
    },
    getCount() {
      return this.$store.state.requests.allActRecon.count
    },
    getContractByClient() {
      return this.$store.state.requests.oneClientContract
    },
    allClientNames() {
      let data = this.$store.state.requests.allClientsName
      data.forEach((element) => {
        element.full_name = element.full_name + ', INN:' + element.inn
      })
      return data
    }
  },
  methods: {
    rowClick: function (item, row) {
      var self = this
      this.counter++
      if (this.counter == 1) {
        this.timer = setTimeout(function () {
          self.counter = 0
          row.select(true)
          this.selectedId = item.id
        }, 200)
      } else {
        clearTimeout(this.timer)
        self.counter = 0
        this.$router.push('/actrecondetails/' + item.id + '/')
        row.select(false)
      }
    },
    getPostData(value) {
      if (parseInt(this.$router.currentRoute.query.page, 10) !== value) {
        this.$router.push({
          path: this.$router.currentRoute.path,
          query: { page: value }
        })
      }
      this.$store.dispatch('getAllActRecontPag', value)
    },
    submit() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        this.submitStatus = 'ERROR'
        this.dialog = true
      } else {
        this.aktSverkaLoading = true
        const data = {
          contract: this.ContractByClientInput.id,
          report_start_date: this.newstart_date2,
          report_end_date: this.end_date
        }
        this.$store
          .dispatch('createAkt', data)
          .then(() => {
            console.log('done!')
            this.aktSverkaLoading = false

            this.ContractByClientInput = ''
            this.start_date = ''
            this.end_date = ''
            this.combobox = ''
            this.$v.$reset()
            this.dialog = false
          })
          .catch((err) => {
            console.error(err)
            this.aktSverkaLoading = false
          })
      }
    },
    setCurrPage(value) {
      this.$store.dispatch('getAllActRecontPag', value)
    },
    clientFun(val) {
      const data = {
        name: val
      }
      this.$store.dispatch('clientSearch', data)
    },
    allClientContract(value) {
      let id = value
      this.$store.dispatch('getOneClientContract', id)
      this.client_contract = true
    }
  },
  mounted() {
    let start_date = new Date()
    let newstart_date = start_date.setDate(start_date.getDate() - 1)
    let newstart_datee = new Date(newstart_date)
    this.newstart_date2 = newstart_datee.toISOString().substr(0, 10)

    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t('BREADCRUMBS.SOLD') },
      { title: this.$t('MENU.SALE.ACT_SVERKA') }
    ])
  },
  components: {
    allActReconAction
  }
}
</script>

<style scoped>
.v-data-table /deep/ .v-data-table__wrapper tbody tr.v-data-table__selected {
  background-color: #efefef !important;
}
.v-application .elevation-1 {
  box-shadow: none !important;
}
.v-data-table /deep/ .v-data-table__wrapper > table > thead > tr > th {
  font-size: 1rem !important;
  color: #000 !important;
  font-weight: 500 !important;
}
table {
  border-collapse: collapse;
  width: 100%;
}
.v-data-table /deep/ .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 1rem !important;
  color: #000 !important;
  height: 60px !important;
}
.v-data-table /deep/ .v-data-table__wrapper {
  overflow-x: scroll !important;
  white-space: nowrap !important;
}
.v-data-table /deep/ .v-data-table__wrapper {
  overflow-x: unset !important;
  overflow-y: unset !important;
}
</style>
